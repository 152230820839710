

















































import Vue from 'vue'
import {Prop,Component,Mixins} from 'vue-property-decorator';
import TablaGeneral from '@/components/General/TablaGeneral.vue';
import Formulario from '@/components/General/Formulario.vue';
import TipoLabor from '@/entidades/Plantacion/TipoLabor';
import ModeloGenerico from '@/Generico/ModeloGenerico';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import MensajeConfirmacion from '@/components/General/Confirmacion.vue';
import {Mutation} from 'vuex-class'
import Loading from '@/entidades/Sistema/Loading'
import Alerta from '@/entidades/Sistema/Alerta'


@Component({
    name : 'TipoLabor',
    components:{
        TablaGeneral,
        Formulario,
        MensajeConfirmacion
    }
})
export default class TipoLaborView extends Vue
{
    @Mutation('mostrarLoading',{namespace:'sistemaModule'}) mostrarLoading!:Function;
	@Mutation('mostarAlert',{namespace:'sistemaModule'}) mostarAlert!:Function;

    header:{text:string,sortable:boolean,value:string}[] =[
            {text:"Descripcion",sortable:true,value:'descripcion'},
            {text:"Editar",sortable:true,value:'actions'},
            {text:"Estado",sortable:true,value:'estado'},
    ]
   listadoTipoLabor:Array<TipoLabor> = [];
   search:string="";

   modalAgregarTipoLabor:boolean =false;
   titulo:string = "";
   action:number = 1;
   modalAgregarTipoLaborName:string = "";
   modeloGenerico:ModeloGenerico |undefined = new ModeloGenerico();
   componenteVersatil:string="";
   valorConfirmacion:boolean = false;
   laborEdicion:TipoLabor |undefined = undefined;
   mensaje:string="";
   async ObtenerTipoLabor()
   {
       try
       {
           this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
           let response = await TipoLabor.Obtener();
           if(response.isSuccess == true)
           {
               this.listadoTipoLabor = response.isResult;
           }
           else
           {
               this.mostarAlert(new Alerta(response.isMessage,true,"danger",2000));
           }
       }
       catch(error)
       {
              //@ts-ignore
           this.mostarAlert(new Alerta(error,true,"danger",2000));
       }finally{
            this.mostrarLoading(new Loading(false,""));
        }
   }

   async guardarDb(id:number,descripcion:string)
   {
       try
       {
           this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
           let tipoLabor  = new TipoLabor();
           tipoLabor.tipoLaborId = id;
           tipoLabor.descripcion = descripcion;
           tipoLabor.estado = true;
           let response: ResponseGenerico  | undefined;
           if(this.action == 1)
                 response = await TipoLabor.Guardar(tipoLabor);
            else 
                 response = await TipoLabor.Editar(tipoLabor);
           if(response.isSuccess == true)
           {
               this.mostarAlert(new Alerta(response.isMessage,true,"danger",2000));
               this.modalAgregarTipoLabor = false;
               this.modalAgregarTipoLaborName = "";
               await this.ObtenerTipoLabor();
           }
           else
           {
               this.mostarAlert(new Alerta(response.isMessage,true,"danger",2000));
           }
       }
       catch(error)
       {
              //@ts-ignore
           this.mostarAlert(new Alerta(error,true,"danger",2000));
       }finally{
            this.mostrarLoading(new Loading(false,""));
    }
   }
    abrirModalAgregar()
    {   this.action = 1;
        this.modeloGenerico = undefined;
        this.titulo = "Registrar Labor";
        this.modalAgregarTipoLabor = true;
        this.modalAgregarTipoLaborName = "Formulario";
    }
    modalEditar(tipoLabor:TipoLabor)
    {
        
       this.action = 2;
        this.titulo=  "Editar Labor";
        this.modeloGenerico = new ModeloGenerico();
        this.modeloGenerico.id = tipoLabor.tipoLaborId;
        this.modeloGenerico.descripcion = tipoLabor.descripcion;
        this.modeloGenerico.estado = tipoLabor.estado;
       this.modalAgregarTipoLaborName = "Formulario";
       this.modalAgregarTipoLabor = true;
    }
    cerrarModal()
    {
        this.action = 1;
        this.modalAgregarTipoLabor =false;
        this.modalAgregarTipoLaborName = "";
        this.modeloGenerico = undefined;
    }
    editarEstado(tipoLabor:TipoLabor)
    {
        try
        {
            this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
            if(tipoLabor.countActivas> 0 && tipoLabor.estado == true)
            {
                this.laborEdicion = tipoLabor;
                this.mensaje = `Si desactiva el Tipo de Labor ${tipoLabor.descripcion}, se procedera a desactivar 
                la cantidad de ${tipoLabor.countActivas} Labores que se encuentra asociadas`;
                this.componenteVersatil = "MensajeConfirmacion";
                return;
            }
            else
            {
                this.editarEstadoDb(tipoLabor);
            }
            
        }
        catch(error)
        {
               //@ts-ignore
            this.mostarAlert(new Alerta(error,true,"danger",2000));
        }
    }

    async editarEstadoDb(tipoLabor:TipoLabor)
    {
        try
        {
            this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
            let response = await TipoLabor.EditarEstado(tipoLabor);
                if(response.isSuccess == true)
                {
                    this.mostarAlert(new Alerta(response.isMessage,true,"danger",2000));
                    this.ObtenerTipoLabor();
                }
                else
                {
                    this.mostarAlert(new Alerta(response.isMessage,true,"danger",2000));
                }
        }
        catch(error)
        {
               //@ts-ignore
            this.mostarAlert(new Alerta(error,true,"danger",2000));
        }
        finally
        {
            this.mostrarLoading(new Loading(false,""));
        }
    }

    confirmarcionEditarEstado(valor:boolean)
    {
        if(valor == true)
        {
            if(this.laborEdicion != undefined)
            this.editarEstadoDb(this.laborEdicion);
        }
    }

    //Computed()
    get componenteDinamico()
    {
         if(this.componenteVersatil == "MensajeConfirmacion")
         {
             let objeto = {'modal' : true, 'mensaje' : this.mensaje};
             return objeto;
         }
    }
   mounted() {
        this.ObtenerTipoLabor();   
   }
}
